@import url(https://fonts.googleapis.com/css?family=Montserrat:600);
.text-container1 {
  /* height: 100vh;
    width: 100vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 15rem; */

}

@media screen and (max-width: 768px) {
  .text-container1{
    margin-top:0rem;
  }
}

.text-container1 h1 {
  color: rgba(225, 225, 225, 0.01);
  background-image: url("https://images.pexels.com/photos/7364066/pexels-photo-7364066.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-animation: animate 5s ease-in-out infinite;
          animation: animate 5s ease-in-out infinite;
}

@-webkit-keyframes animate {
  0%,
  100% {
    background-position: left top;
  }
  25% {
    background-position: right bottom;
  }
  50% {
    background-position: left bottom;
  }
  75% {
    background-position: right top;
  }
}

@keyframes animate {
  0%,
  100% {
    background-position: left top;
  }
  25% {
    background-position: right bottom;
  }
  50% {
    background-position: left bottom;
  }
  75% {
    background-position: right top;
  }
}

*{
--orange: '#fed693'
}
.heroneon {
    position: relative;
    display: inline-block;
    padding: 20px 25px;
    margin: 20px 0;
    color: #ff0000;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.5s;
    letter-spacing: 4px;
    overflow: hidden;
    font-size: 1.2rem;
    font-weight: 700;
  }

  @media screen and (max-width: 768px) {
    .heroneon {
      font-size: 1rem;
    }
  }
  .heroneon:hover {
    background: linear-gradient(-45deg, #ffb401, #ff0000);
    color: #fff;
    box-shadow: 0 0 5px #f39a05, 0 0 25px #f39a05, 0 0 50px #f39a05,
      0 0 200px #f39a05;
    -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
  }
  .heroneon:nth-child(1) {
    -webkit-filter: hue-rotate(0deg);
            filter: hue-rotate(0deg);
  }
  .heroneon:nth-child(2) {
    -webkit-filter: hue-rotate(110deg);
            filter: hue-rotate(110deg);
  }
  .heroneon span {
    position: absolute;
    display: block;
  }
  .heroneon span:nth-child(1) {
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #ff0000);
    -webkit-animation: animate1 1s linear infinite;
            animation: animate1 1s linear infinite;
  }
  @-webkit-keyframes animate1 {
    0% {
      left: -100%;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  @keyframes animate1 {
    0% {
      left: -100%;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  .heroneon span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #ff0000);
    -webkit-animation: animate2 1s linear infinite;
            animation: animate2 1s linear infinite;
    -webkit-animation-delay: 0.25s;
            animation-delay: 0.25s;
  }
  @-webkit-keyframes animate2 {
    0% {
      top: -100%;
    }
    50%,
    100% {
      top: 100%;
    }
  }
  @keyframes animate2 {
    0% {
      top: -100%;
    }
    50%,
    100% {
      top: 100%;
    }
  }
  .heroneon span:nth-child(3) {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #ff0000);
    -webkit-animation: animate3 1s linear infinite;
            animation: animate3 1s linear infinite;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  @-webkit-keyframes animate3 {
    0% {
      right: -100%;
    }
    50%,
    100% {
      right: 100%;
    }
  }
  @keyframes animate3 {
    0% {
      right: -100%;
    }
    50%,
    100% {
      right: 100%;
    }
  }
  .heroneon span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #ff0000);
    -webkit-animation: animate4 1s linear infinite;
            animation: animate4 1s linear infinite;
    -webkit-animation-delay: 0.75s;
            animation-delay: 0.75s;
  }
  @-webkit-keyframes animate4 {
    0% {
      bottom: -100%;
    }
    50%,
    100% {
      bottom: 100%;
    }
  }
  @keyframes animate4 {
    0% {
      bottom: -100%;
    }
    50%,
    100% {
      bottom: 100%;
    }
  }
.testimonial {
    width: 400px;
    max-width: 100%;
    background: #f9f9f8;
    padding: 4em 3em;
    margin: 2rem;
    display: flex;
    align-items: flex-end;
    position: relative;
    box-shadow: 0 2px 2px #fff, 0 3px 3px #000, 0 5px 5px #000, 0 9px 9px #000, 0 17px 17px #000;
    position: relative;
}
.testimonial:after {
    content: "";
    border: 8px solid #f9f9f8;
    border-radius: 50px;
    width: 85%;
    height: 120%;
    position: absolute;
    z-index: -1;
    left: 1.5em;
    top: -2em;
}
/* .testimonial:before {
    content: "";
    position: absolute;
    bottom: -5.2em;
    left: 5em;
    z-index: 1;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 70px 100px 0 0;
    border-color: #f9f9f8 transparent transparent transparent;
} */
.testimonial .quote {
    position: absolute;
    font-size: 3em;
    width: 40px;
    height: 40px;
    background: red;
    color: white;
    text-align: center;
    line-height: 1.25;
}
.testimonial .quote.open {
    top: 0;
    left: 0;
}
.testimonial .quote.close {
    bottom: 0;
    right: 0;
}
.testimonial p {
    width: 80%;
    display: inline-block;
    font-weight: bold;
    font-size: 1em;
    color: #000;
}
.testimonial .source {
    width: 40%;
    height: 100%;
    position: relative;
}
.testimonial .source span {
    display: inline-block;
    font-weight: bold;
    font-size: 1em;
    position: relative;
    margin-left: 1rem;
    text-align: right;
}
.testimonial .source span:before {
    content: "\2014";
    display: inline;
    margin-right: 5px;
}
.testimonial .image {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    position: absolute;
    top: 0.5em;
    right: 1.5em;
}
.testimonial .image img {
    border: 5px solid red;
    margin: 0;
    padding: 0;
    max-width:100px;
}
.testimonial .image .clip {
    border: 2px solid #fff;
    border-right: none;
    height: 75px;
    width: 20px;
    position: absolute;
    right: 30%;
    top: -15%;
    border-radius: 25px;
}
.testimonial .image .clip:before {
    content: "";
    position: absolute;
    top: -1px;
    right: 0;
    height: 10px;
    width: 16px;
    border: 2px solid #222;
    border-bottom: none;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    z-index: 99;
}
.testimonial .image .clip:after {
    content: "";
    position: absolute;
    bottom: -1px;
    right: 0;
    height: 40px;
    width: 16px;
    border: 2px solid #222;
    border-top: none;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    z-index: 99;
}
.slide-in {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #8f1f1e;
    -webkit-transform-origin: left;
            transform-origin: left;
    z-index: 2000;
  }
  
  
  .slide-out {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background: #0f0f0f;
      -webkit-transform-origin:  right;
              transform-origin:  right;
      z-index: 2000;
    }


/* ////Start transition/// */

.stairs .transition-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: 2;
}
.stairs .transition-container div {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #8f1f1e;
}
.stairs .transition-background {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: yellow;
  z-index: 1;
  pointer-events: none;
  top: 0;
  left: 0;
}








.curve svg, .curve .background {
    position: fixed;
    height: calc(100vh + 600px);
    width: 100vw;
    pointer-events: none;
    left: 0;
    top: -500px;
}
 .background {
    background-color: #000;
    transition: opacity 0s linear 0.1s;
}
 .route {
    position: absolute;
    left: 50%;
    top: 40%;
    color: white;
    font-size: 46px;
    z-index: 3;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    text-align: center;
}

#processing-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  background-color: rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 9999;
}

#wrapper {
  position: relative;
  max-width: 400px;
  min-width: 300px;
  margin: 1rem;
  -webkit-animation: out 0.3s forwards ease;
          animation: out 0.3s forwards ease;
  -webkit-animation-delay: 5s;
          animation-delay: 5s;
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
  -webkit-transform-origin: center -80%;
          transform-origin: center -80%;
  margin: 0 auto;
  height: auto; /* Flexible height */

  backdrop-filter: blur(10px);

  -webkit-backdrop-filter: blur(10px);

  /* background-color: rgba(255, 255, 255, 0.1); */
}

@-webkit-keyframes out {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }
}

@keyframes out {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }
}

#wrapper .loader {
  -webkit-animation: rotation 3.5s forwards linear;
          animation: rotation 3.5s forwards linear;
  position: absolute;
  top: -120px;
  left: calc(50% - 35px);
  border: 5px solid #000;
  border-radius: 50%;
  border-top-color: #ff0000;
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  84% {
    -webkit-transform: rotate(500deg);
            transform: rotate(500deg);
  }
  95% {
    border: 5px solid #000;
    border-top-color: #ff0000;
  }
  100% {
    -webkit-transform: rotate(1800deg);
            transform: rotate(1800deg);
    border: 5px solid #ff0000;
  }
}

@keyframes rotation {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  84% {
    -webkit-transform: rotate(500deg);
            transform: rotate(500deg);
  }
  95% {
    border: 5px solid #000;
    border-top-color: #ff0000;
  }
  100% {
    -webkit-transform: rotate(1800deg);
            transform: rotate(1800deg);
    border: 5px solid #ff0000;
  }
}

#wrapper .loader::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 2em;
  color: #ff0000;
  -webkit-animation: overspin 0.5s forwards cubic-bezier(0.175, 0.885, 0.32, 1.275);
          animation: overspin 0.5s forwards cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-animation-delay: 3.4s;
          animation-delay: 3.4s;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  opacity: 0;
}

@-webkit-keyframes overspin {
  0% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
}

@keyframes overspin {
  0% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
}

#wrapper #mouse {
  position: absolute;
  top: 100px;
  left: 80%;
  -webkit-animation: mouse-pos 3.5s forwards, mouse-pos2 1s forwards;
          animation: mouse-pos 3.5s forwards, mouse-pos2 1s forwards;
  -webkit-animation-delay: 0s, 4s;
          animation-delay: 0s, 4s;
  z-index: 20;
}

@-webkit-keyframes mouse-pos {
  30% {
    top: 100px;
    left: 80%;
  }
  50% {
    top: 15px;
    left: 4%;
  }
  60% {
    top: 15px;
    left: 4%;
  }
  75% {
    top: 5px;
    left: 7.3%;
  }
  85% {
    top: 5px;
    left: 7.3%;
  }
  95% {
    top: 15px;
    left: 105%;
  }
  100% {
    top: 15px;
    left: 105%;
  }
}

@keyframes mouse-pos {
  30% {
    top: 100px;
    left: 80%;
  }
  50% {
    top: 15px;
    left: 4%;
  }
  60% {
    top: 15px;
    left: 4%;
  }
  75% {
    top: 5px;
    left: 7.3%;
  }
  85% {
    top: 5px;
    left: 7.3%;
  }
  95% {
    top: 15px;
    left: 105%;
  }
  100% {
    top: 15px;
    left: 105%;
  }
}

@-webkit-keyframes mouse-pos2 {
  0% {
    top: 15px;
    left: 105%;
  }
  100% {
    top: -85px;
    left: 53%;
  }
}

@keyframes mouse-pos2 {
  0% {
    top: 15px;
    left: 105%;
  }
  100% {
    top: -85px;
    left: 53%;
  }
}

#wrapper #mouse::after {
  content: "\f245";
  position: absolute;
  top: 0;
  left: 0;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 20px;
  -webkit-animation: mouse-cont 2s forwards;
          animation: mouse-cont 2s forwards;
  -webkit-animation-delay: 1.7s;
          animation-delay: 1.7s;
  color: #ff0000;
  text-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);
}

@-webkit-keyframes mouse-cont {
  0% {
    font-size: 20px;
  }
  2% {
    font-size: 15px;
  }
  4% {
    font-size: 20px;
  }
  33% {
    content: "\f245";
  }
  34% {
    content: "\f337";
  }
  80% {
    content: "\f337";
  }
  81% {
    content: "\f245";
  }
}

@keyframes mouse-cont {
  0% {
    font-size: 20px;
  }
  2% {
    font-size: 15px;
  }
  4% {
    font-size: 20px;
  }
  33% {
    content: "\f245";
  }
  34% {
    content: "\f337";
  }
  80% {
    content: "\f337";
  }
  81% {
    content: "\f245";
  }
}

#wrapper #mouse::before {
  content: "";
  position: absolute;
  top: -22px;
  left: -24px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #222f3e;
  -webkit-animation: circle 3.5s forwards;
          animation: circle 3.5s forwards;
  -webkit-animation-delay: 1.7s;
          animation-delay: 1.7s;
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
}

@-webkit-keyframes circle {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  4% {
    opacity: 1;
  }
  8% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
  92% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }
  95% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
}

@keyframes circle {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  4% {
    opacity: 1;
  }
  8% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
  92% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }
  95% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
}

#wrapper .loading-bar {
  width: 100%;
  height: 30px;
  background: #dfe6e9;
  border-radius: 5px;
}

#wrapper .progress-bar {
  -webkit-animation: progress 3.5s forwards;
          animation: progress 3.5s forwards;
  width: 6%;
  height: 100%;
  background: #ff0000;
  border-radius: 5px;
  border: 0 solid #c1c1c1;
}

@-webkit-keyframes progress {
  0% {
    width: 6%;
  }
  50% {
    border: 0 solid #c1c1c1;
  }
  51% {
    border: 4px solid #c1c1c1;
  }
  85% {
    width: 11%;
  }
  95% {
    border: 4px solid #c1c1c1;
    width: 100%;
  }
  100% {
    width: 100%;
  }
}

@keyframes progress {
  0% {
    width: 6%;
  }
  50% {
    border: 0 solid #c1c1c1;
  }
  51% {
    border: 4px solid #c1c1c1;
  }
  85% {
    width: 11%;
  }
  95% {
    border: 4px solid #c1c1c1;
    width: 100%;
  }
  100% {
    width: 100%;
  }
}

#wrapper .status {
  margin-top: 10px;
}

#wrapper .state {
  float: left;
  font-size: 0.9em;
  letter-spacing: 1pt;
  text-transform: uppercase;
  width: 100px;
  height: 20px;
  position: relative;
}

#wrapper .state::before {
  content: "Uploading...";
  position: absolute;
  left: 00%;
  top: 0;
  -webkit-animation: fadeLeft 0.5s forwards ease;
          animation: fadeLeft 0.5s forwards ease;
  -webkit-animation-delay: 3.2s;
          animation-delay: 3.2s;
}

@-webkit-keyframes fadeLeft {
  0% {
    text-indent: 0;
    opacity: 1;
  }
  100% {
    text-indent: -100px;
    opacity: 0;
  }
}

@keyframes fadeLeft {
  0% {
    text-indent: 0;
    opacity: 1;
  }
  100% {
    text-indent: -100px;
    opacity: 0;
  }
}

#wrapper .state::after {
  content: "Complete";
  position: absolute;
  left: 0;
  top: 0;
  text-indent: 100px;
  opacity: 0;
  -webkit-animation: fadeLeft2 0.5s forwards ease;
          animation: fadeLeft2 0.5s forwards ease;
  -webkit-animation-delay: 3.2s;
          animation-delay: 3.2s;
}

@-webkit-keyframes fadeLeft2 {
  0% {
    text-indent: 100px;
    opacity: 0;
  }
  100% {
    text-indent: 0;
    opacity: 1;
  }
}

@keyframes fadeLeft2 {
  0% {
    text-indent: 100px;
    opacity: 0;
  }
  100% {
    text-indent: 0;
    opacity: 1;
  }
}

#wrapper .percentage {
  float: right;
}

#wrapper .percentage::before {
  content: "100%";
  font-size: 0.9em;
  letter-spacing: 1pt;
  -webkit-animation: percentage-slow 3s forwards, percentage-fast 0.4s forwards;
          animation: percentage-slow 3s forwards, percentage-fast 0.4s forwards;
  -webkit-animation-delay: 0s, 3s;
          animation-delay: 0s, 3s;
}

@-webkit-keyframes percentage-slow {
  0% {
    content: "6%";
  }
  25% {
    content: "7%";
  }
  50% {
    content: "8%";
  }
  75% {
    content: "9%";
  }
  100% {
    content: "10%";
  }
}

@keyframes percentage-slow {
  0% {
    content: "6%";
  }
  25% {
    content: "7%";
  }
  50% {
    content: "8%";
  }
  75% {
    content: "9%";
  }
  100% {
    content: "10%";
  }
}

@-webkit-keyframes percentage-fast {
  20% {
    content: "20%";
  }
  30% {
    content: "30%";
  }
  40% {
    content: "40%";
  }
  50% {
    content: "50%";
  }
  60% {
    content: "60%";
  }
  70% {
    content: "70%";
  }
  80% {
    content: "80%";
  }
  90% {
    content: "90%";
  }
  100% {
    content: "100%";
  }
}

@keyframes percentage-fast {
  20% {
    content: "20%";
  }
  30% {
    content: "30%";
  }
  40% {
    content: "40%";
  }
  50% {
    content: "50%";
  }
  60% {
    content: "60%";
  }
  70% {
    content: "70%";
  }
  80% {
    content: "80%";
  }
  90% {
    content: "90%";
  }
  100% {
    content: "100%";
  }
}

.voltage-button {
  position: relative;
}

.voltage-button button {
  color: white;
  background: transparent;
  padding: 10px 15px;
  border-radius: 5rem;
  border: 3px solid #fff;
  font-size: 1rem;
  line-height: 1em;
  letter-spacing: 0.075em;
  transition: background 0.3s;
  text-transform: uppercase;
}

@media screen and (max-width: 960px) {
  .voltage-button button {
    border: 3px solid #fff;
    padding: 15px 25px;
    text-transform: uppercase;
  }
}

.voltage-button button:hover {
  cursor: pointer;
  background: #fff;
  color: #ff0000;
  border: 3px solid #ff0000;
}

.voltage-button button + svg,
.voltage-button button + svg + .dots {
  opacity: 0.5;
}

@media screen and (max-width: 960px) {
  .voltage-button button + svg,
  .voltage-button button + svg + .dots {
    opacity: 0.8;
  }
}

.voltage-button button:hover + svg,
.voltage-button button:hover + svg + .dots {
  opacity: 0.8;
}

.voltage-button svg {
  display: block;
  position: absolute;
  top: -0.75em;
  left: -0.25em;
  width: calc(100% + 0.5em);
  height: calc(100% + 1.5em);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s;
  transition-delay: 0.1s;
}

.voltage-button svg path {
  stroke-dasharray: 100;
  -webkit-filter: url("#glow");
          filter: url("#glow");
}

.voltage-button svg path.line-1 {
  stroke: #ebd197;
  stroke-dashoffset: 0;
  -webkit-animation: spark-1 3s linear infinite;
          animation: spark-1 3s linear infinite;
}



.voltage-button svg path.line-2 {
  stroke: #ff0000;
  stroke-dashoffset: 500;
  -webkit-animation: spark-2 3s linear infinite;
          animation: spark-2 3s linear infinite;
}

@media screen and (max-width: 960px) {
  .voltage-button svg path.line-1 {
    stroke: #bb9b49;
  }
  .voltage-button svg path.line-2 {
    stroke: #ebd197;
  }


}

.voltage-button .dots {
  opacity: 0;
  transition: opacity 0.3s;
  transition-delay: 0.4s;
}

.voltage-button .dots .dot {
  width: 1rem;
  height: 1rem;
  background: #fff;
  border-radius: 100%;
  position: absolute;
  opacity: 0;
}

.voltage-button .dots .dot-1 {
  top: 0;
  left: 20%;
  -webkit-animation: fly-up 3s linear infinite;
          animation: fly-up 3s linear infinite;
}

.voltage-button .dots .dot-2 {
  top: 0;
  left: 55%;
  -webkit-animation: fly-up 3s linear infinite;
          animation: fly-up 3s linear infinite;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

.voltage-button .dots .dot-3 {
  top: 0;
  left: 80%;
  -webkit-animation: fly-up 3s linear infinite;
          animation: fly-up 3s linear infinite;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.voltage-button .dots .dot-4 {
  bottom: 0;
  left: 30%;
  -webkit-animation: fly-down 3s linear infinite;
          animation: fly-down 3s linear infinite;
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s;
}

.voltage-button .dots .dot-5 {
  bottom: 0;
  left: 65%;
  -webkit-animation: fly-down 3s linear infinite;
          animation: fly-down 3s linear infinite;
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
}

@-webkit-keyframes spark-1 {
  to {
    stroke-dashoffset: -1000;
  }
}

@keyframes spark-1 {
  to {
    stroke-dashoffset: -1000;
  }
}

@-webkit-keyframes spark-2 {
  to {
    stroke-dashoffset: -500;
  }
}

@keyframes spark-2 {
  to {
    stroke-dashoffset: -500;
  }
}

@-webkit-keyframes fly-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(0) scale(0.2);
            transform: translateY(0) scale(0.2);
  }

  5% {
    opacity: 1;
    -webkit-transform: translateY(-1.5rem) scale(0.4);
            transform: translateY(-1.5rem) scale(0.4);
  }

  10%,
  100% {
    opacity: 0;
    -webkit-transform: translateY(-3rem) scale(0.2);
            transform: translateY(-3rem) scale(0.2);
  }
}

@keyframes fly-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(0) scale(0.2);
            transform: translateY(0) scale(0.2);
  }

  5% {
    opacity: 1;
    -webkit-transform: translateY(-1.5rem) scale(0.4);
            transform: translateY(-1.5rem) scale(0.4);
  }

  10%,
  100% {
    opacity: 0;
    -webkit-transform: translateY(-3rem) scale(0.2);
            transform: translateY(-3rem) scale(0.2);
  }
}

@-webkit-keyframes fly-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(0) scale(0.2);
            transform: translateY(0) scale(0.2);
  }

  5% {
    opacity: 1;
    -webkit-transform: translateY(1.5rem) scale(0.4);
            transform: translateY(1.5rem) scale(0.4);
  }

  10%,
  100% {
    opacity: 0;
    -webkit-transform: translateY(3rem) scale(0.2);
            transform: translateY(3rem) scale(0.2);
  }
}

@keyframes fly-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(0) scale(0.2);
            transform: translateY(0) scale(0.2);
  }

  5% {
    opacity: 1;
    -webkit-transform: translateY(1.5rem) scale(0.4);
            transform: translateY(1.5rem) scale(0.4);
  }

  10%,
  100% {
    opacity: 0;
    -webkit-transform: translateY(3rem) scale(0.2);
            transform: translateY(3rem) scale(0.2);
  }
}

*{
--orange: '#fed693'
}
.heroneon_white {
    position: relative;
    display: inline-block;
    padding: 20px 25px;
    margin: 20px 0;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.5s;
    letter-spacing: 4px;
    overflow: hidden;
    font-size: 1.2rem;
    font-weight: 700;
  }

  @media screen and (max-width: 768px) {
    .heroneon_white {
      font-size: 1rem;
    }
  }
  .heroneon_white:hover {
    background: linear-gradient(-45deg, #ffb401, #ff0000);
    color: #fff;
    box-shadow: 0 0 5px #f39a05, 0 0 25px #f39a05, 0 0 50px #f39a05,
      0 0 200px #f39a05;
    -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
  }
  .heroneon_white:nth-child(1) {
    -webkit-filter: hue-rotate(0deg);
            filter: hue-rotate(0deg);
  }
  .heroneon_white:nth-child(2) {
    -webkit-filter: hue-rotate(110deg);
            filter: hue-rotate(110deg);
  }
  .heroneon_white span {
    position: absolute;
    display: block;
  }
  .heroneon_white span:nth-child(1) {
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #fff);
    -webkit-animation: animate1 1s linear infinite;
            animation: animate1 1s linear infinite;
  }
  @-webkit-keyframes animate1 {
    0% {
      left: -100%;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  @keyframes animate1 {
    0% {
      left: -100%;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  .heroneon_white span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #fff);
    -webkit-animation: animate2 1s linear infinite;
            animation: animate2 1s linear infinite;
    -webkit-animation-delay: 0.25s;
            animation-delay: 0.25s;
  }
  @-webkit-keyframes animate2 {
    0% {
      top: -100%;
    }
    50%,
    100% {
      top: 100%;
    }
  }
  @keyframes animate2 {
    0% {
      top: -100%;
    }
    50%,
    100% {
      top: 100%;
    }
  }
  .heroneon_white span:nth-child(3) {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #fff);
    -webkit-animation: animate3 1s linear infinite;
            animation: animate3 1s linear infinite;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  @-webkit-keyframes animate3 {
    0% {
      right: -100%;
    }
    50%,
    100% {
      right: 100%;
    }
  }
  @keyframes animate3 {
    0% {
      right: -100%;
    }
    50%,
    100% {
      right: 100%;
    }
  }
  .heroneon_white span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #fff);
    -webkit-animation: animate4 1s linear infinite;
            animation: animate4 1s linear infinite;
    -webkit-animation-delay: 0.75s;
            animation-delay: 0.75s;
  }
  @-webkit-keyframes animate4 {
    0% {
      bottom: -100%;
    }
    50%,
    100% {
      bottom: 100%;
    }
  }
  @keyframes animate4 {
    0% {
      bottom: -100%;
    }
    50%,
    100% {
      bottom: 100%;
    }
  }
*{
--orange: '#fed693'
}
.heroneon {
    position: relative;
    display: inline-block;
    padding: 20px 25px;
    margin: 20px 0;
    color: #ff0000;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.5s;
    letter-spacing: 4px;
    overflow: hidden;
    font-size: 1.2rem;
    font-weight: 700;
  }

  @media screen and (max-width: 768px) {
    .heroneon {
      font-size: 1rem;
    }
  }
  .heroneon:hover {
    background: linear-gradient(-45deg, #ffb401, #ff0000);
    color: #fff;
    box-shadow: 0 0 5px #f39a05, 0 0 25px #f39a05, 0 0 50px #f39a05,
      0 0 200px #f39a05;
    -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
  }
  .heroneon:nth-child(1) {
    -webkit-filter: hue-rotate(0deg);
            filter: hue-rotate(0deg);
  }
  .heroneon:nth-child(2) {
    -webkit-filter: hue-rotate(110deg);
            filter: hue-rotate(110deg);
  }
  .heroneon span {
    position: absolute;
    display: block;
  }
  .heroneon span:nth-child(1) {
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #ff0000);
    -webkit-animation: animate1 1s linear infinite;
            animation: animate1 1s linear infinite;
  }
  @-webkit-keyframes animate1 {
    0% {
      left: -100%;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  @keyframes animate1 {
    0% {
      left: -100%;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  .heroneon span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #ff0000);
    -webkit-animation: animate2 1s linear infinite;
            animation: animate2 1s linear infinite;
    -webkit-animation-delay: 0.25s;
            animation-delay: 0.25s;
  }
  @-webkit-keyframes animate2 {
    0% {
      top: -100%;
    }
    50%,
    100% {
      top: 100%;
    }
  }
  @keyframes animate2 {
    0% {
      top: -100%;
    }
    50%,
    100% {
      top: 100%;
    }
  }
  .heroneon span:nth-child(3) {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #ff0000);
    -webkit-animation: animate3 1s linear infinite;
            animation: animate3 1s linear infinite;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  @-webkit-keyframes animate3 {
    0% {
      right: -100%;
    }
    50%,
    100% {
      right: 100%;
    }
  }
  @keyframes animate3 {
    0% {
      right: -100%;
    }
    50%,
    100% {
      right: 100%;
    }
  }
  .heroneon span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #ff0000);
    -webkit-animation: animate4 1s linear infinite;
            animation: animate4 1s linear infinite;
    -webkit-animation-delay: 0.75s;
            animation-delay: 0.75s;
  }
  @-webkit-keyframes animate4 {
    0% {
      bottom: -100%;
    }
    50%,
    100% {
      bottom: 100%;
    }
  }
  @keyframes animate4 {
    0% {
      bottom: -100%;
    }
    50%,
    100% {
      bottom: 100%;
    }
  }
.text-container {
  /* height: 100vh;
    width: 100vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 15rem; */

}

@media screen and (max-width: 768px) {
  .text-container{
    margin-top: 5rem;
  }
}

.text-container h1 {
  color: rgba(225, 225, 225, 0.01);
  background-image: url("https://images.pexels.com/photos/73873/star-clusters-rosette-nebula-star-galaxies-73873.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-animation: animate 5s ease-in-out infinite;
          animation: animate 5s ease-in-out infinite;
}

@-webkit-keyframes animate {
  0%,
  100% {
    background-position: left top;
  }
  25% {
    background-position: right bottom;
  }
  50% {
    background-position: left bottom;
  }
  75% {
    background-position: right top;
  }
}

@keyframes animate {
  0%,
  100% {
    background-position: left top;
  }
  25% {
    background-position: right bottom;
  }
  50% {
    background-position: left bottom;
  }
  75% {
    background-position: right top;
  }
}

.text-container2 {
  /* height: 100vh;
    width: 100vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 15rem; */

}

@media screen and (max-width: 768px) {
  .text-container2{
    margin-top: 0rem;
  }
}

.text-container2 h1 {
  color: rgba(225, 225, 225, 0.01);
  background-image: url("https://images.pexels.com/photos/266834/pexels-photo-266834.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-animation: animate 5s ease-in-out infinite;
          animation: animate 5s ease-in-out infinite;
}

@-webkit-keyframes animate {
  0%,
  100% {
    background-position: left top;
  }
  25% {
    background-position: right bottom;
  }
  50% {
    background-position: left bottom;
  }
  75% {
    background-position: right top;
  }
}

@keyframes animate {
  0%,
  100% {
    background-position: left top;
  }
  25% {
    background-position: right bottom;
  }
  50% {
    background-position: left bottom;
  }
  75% {
    background-position: right top;
  }
}

*{
--orange: '#fed693'
}
.heroneon {
    position: relative;
    display: inline-block;
    padding: 20px 25px;
    margin: 20px 0;
    color: #ff0000;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.5s;
    letter-spacing: 4px;
    overflow: hidden;
    font-size: 1.2rem;
    font-weight: 700;
  }

  @media screen and (max-width: 768px) {
    .heroneon {
      font-size: 1rem;
    }
  }
  .heroneon:hover {
    background: linear-gradient(-45deg, #ffb401, #ff0000);
    color: #fff;
    box-shadow: 0 0 5px #f39a05, 0 0 25px #f39a05, 0 0 50px #f39a05,
      0 0 200px #f39a05;
    -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
  }
  .heroneon:nth-child(1) {
    -webkit-filter: hue-rotate(0deg);
            filter: hue-rotate(0deg);
  }
  .heroneon:nth-child(2) {
    -webkit-filter: hue-rotate(110deg);
            filter: hue-rotate(110deg);
  }
  .heroneon span {
    position: absolute;
    display: block;
  }
  .heroneon span:nth-child(1) {
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #ff0000);
    -webkit-animation: animate1 1s linear infinite;
            animation: animate1 1s linear infinite;
  }
  @-webkit-keyframes animate1 {
    0% {
      left: -100%;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  @keyframes animate1 {
    0% {
      left: -100%;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  .heroneon span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #ff0000);
    -webkit-animation: animate2 1s linear infinite;
            animation: animate2 1s linear infinite;
    -webkit-animation-delay: 0.25s;
            animation-delay: 0.25s;
  }
  @-webkit-keyframes animate2 {
    0% {
      top: -100%;
    }
    50%,
    100% {
      top: 100%;
    }
  }
  @keyframes animate2 {
    0% {
      top: -100%;
    }
    50%,
    100% {
      top: 100%;
    }
  }
  .heroneon span:nth-child(3) {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #ff0000);
    -webkit-animation: animate3 1s linear infinite;
            animation: animate3 1s linear infinite;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  @-webkit-keyframes animate3 {
    0% {
      right: -100%;
    }
    50%,
    100% {
      right: 100%;
    }
  }
  @keyframes animate3 {
    0% {
      right: -100%;
    }
    50%,
    100% {
      right: 100%;
    }
  }
  .heroneon span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #ff0000);
    -webkit-animation: animate4 1s linear infinite;
            animation: animate4 1s linear infinite;
    -webkit-animation-delay: 0.75s;
            animation-delay: 0.75s;
  }
  @-webkit-keyframes animate4 {
    0% {
      bottom: -100%;
    }
    50%,
    100% {
      bottom: 100%;
    }
  }
  @keyframes animate4 {
    0% {
      bottom: -100%;
    }
    50%,
    100% {
      bottom: 100%;
    }
  }
.text-container1 {
  /* height: 100vh;
    width: 100vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 15rem; */

}

@media screen and (max-width: 768px) {
  .text-container{
    margin-top: 5rem;
  }
}

.text-container1 h1 {
  color: rgba(225, 225, 225, 0.01);
  background-image: url("https://images.pexels.com/photos/7364213/pexels-photo-7364213.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-animation: animate 5s ease-in-out infinite;
          animation: animate 5s ease-in-out infinite;
}

@-webkit-keyframes animate {
  0%,
  100% {
    background-position: left top;
  }
  25% {
    background-position: right bottom;
  }
  50% {
    background-position: left bottom;
  }
  75% {
    background-position: right top;
  }
}

@keyframes animate {
  0%,
  100% {
    background-position: left top;
  }
  25% {
    background-position: right bottom;
  }
  50% {
    background-position: left bottom;
  }
  75% {
    background-position: right top;
  }
}

.quotes {
    position: relative;
    width: 350px;
    margin: 1rem;
    padding: 1rem;
    background-color: transparent;
    background-image: linear-gradient(-45deg, transparent, transparent 2%, white 2%, white 85%, transparent, 85%, transparent), linear-gradient(to bottom right, #2f2f2f, #2f2f2f 90%, transparent 90%);
    background-position: top left, 0.2em 0.2em;
    background-repeat: no-repeat;
    -webkit-filter: drop-shadow(0 0 15px #000 5);
            filter: drop-shadow(0 0 15px #000 5);
    height: 300px;
}
.quotes::before, .quotes::after {
    position: absolute;
    z-index: -1;
    display: block;
    width: 3rem;
    height: 4rem;
    content: "";
    z-index: 0;
    font-size: 8rem;
}
.quotes::before {
    top: -3.5rem;
    left: 1rem;
    content: open-quote;
    color: #ff0000;
}
.quotes::after {
    bottom: -0.5rem;
    right: 1rem;
    content: close-quote;
    color: #ff0000;
}
.quotes::before, .quotes::after, .quotes h2 {
    text-shadow: -2px 2px #fff, -1.5px 1.5px #fff, -1px 1px #fff, -0.5px 0.5px #fff;
}
.h2-quote {
    margin-top: 2rem;
}

.h6-quote {
    margin-top: 1.5rem;
    margin: 0 auto;
    text-align: center;
    max-width: 80%;
}



.p-quote {
    margin: 1rem;
    text-indent: 2ch;
    text-align: justify;
}


.wrapper {
    padding: 10px;
    /* background: #eaeaea; */
    max-width: 400px;
    margin-top: 20px ;
  }
  
  .demo-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  
  .demo-2 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 400px;
  }
*{
--orange: '#fed693'
}
.heroneon_white {
    position: relative;
    display: inline-block;
    padding: 20px 25px;
    margin: 20px 0;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.5s;
    letter-spacing: 4px;
    overflow: hidden;
    font-size: 1.2rem;
    font-weight: 700;
  }

  @media screen and (max-width: 768px) {
    .heroneon_white {
      font-size: 1rem;
    }
  }
  .heroneon_white:hover {
    background: linear-gradient(-45deg, #ffb401, #ff0000);
    color: #fff;
    box-shadow: 0 0 5px #f39a05, 0 0 25px #f39a05, 0 0 50px #f39a05,
      0 0 200px #f39a05;
    -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
  }
  .heroneon_white:nth-child(1) {
    -webkit-filter: hue-rotate(0deg);
            filter: hue-rotate(0deg);
  }
  .heroneon_white:nth-child(2) {
    -webkit-filter: hue-rotate(110deg);
            filter: hue-rotate(110deg);
  }
  .heroneon_white span {
    position: absolute;
    display: block;
  }
  .heroneon_white span:nth-child(1) {
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #fff);
    -webkit-animation: animate1 1s linear infinite;
            animation: animate1 1s linear infinite;
  }
  @-webkit-keyframes animate1 {
    0% {
      left: -100%;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  @keyframes animate1 {
    0% {
      left: -100%;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  .heroneon_white span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #fff);
    -webkit-animation: animate2 1s linear infinite;
            animation: animate2 1s linear infinite;
    -webkit-animation-delay: 0.25s;
            animation-delay: 0.25s;
  }
  @-webkit-keyframes animate2 {
    0% {
      top: -100%;
    }
    50%,
    100% {
      top: 100%;
    }
  }
  @keyframes animate2 {
    0% {
      top: -100%;
    }
    50%,
    100% {
      top: 100%;
    }
  }
  .heroneon_white span:nth-child(3) {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #fff);
    -webkit-animation: animate3 1s linear infinite;
            animation: animate3 1s linear infinite;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  @-webkit-keyframes animate3 {
    0% {
      right: -100%;
    }
    50%,
    100% {
      right: 100%;
    }
  }
  @keyframes animate3 {
    0% {
      right: -100%;
    }
    50%,
    100% {
      right: 100%;
    }
  }
  .heroneon_white span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #fff);
    -webkit-animation: animate4 1s linear infinite;
            animation: animate4 1s linear infinite;
    -webkit-animation-delay: 0.75s;
            animation-delay: 0.75s;
  }
  @-webkit-keyframes animate4 {
    0% {
      bottom: -100%;
    }
    50%,
    100% {
      bottom: 100%;
    }
  }
  @keyframes animate4 {
    0% {
      bottom: -100%;
    }
    50%,
    100% {
      bottom: 100%;
    }
  }
/* @import url("https://fonts.googleapis.com/css?family=Raleway"); */

:root {
  /* --glow-color: hsl(186 100% 69%); */
  --glow-color: #ff0000;

}

/* *,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
} */

.glowing-btn1 {
  /* position: relative; */
  color: #ff0000;
  color: var(--glow-color);
  cursor: pointer;
  padding: 0.35em 1em;
  border: 0.15em solid #ff0000;
  border: 0.15em solid var(--glow-color);
  border-radius: 0.45em;
  background: none;
  -webkit-perspective: 2em;
          perspective: 2em;
  font-size: 1.5em;
  font-weight: 900;
  letter-spacing: 0.8em;
  margin-top: 0.5em;
  box-shadow: inset 0px 0px 0.5em 0px #ff0000,
    0px 0px 0.5em 0px #ff0000;
  box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  -webkit-animation: border-flicker 2s linear infinite;
          animation: border-flicker 2s linear infinite;
}

.glowing-txt {
  float: left;
  margin-right: -0.8em;
  -webkit-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
    0 0 0.45em #ff0000;
  -webkit-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
    0 0 0.45em var(--glow-color);
  -moz-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
    0 0 0.45em #ff0000;
  -moz-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
    0 0 0.45em var(--glow-color);
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em #ff0000;
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em var(--glow-color);
  -webkit-animation: text-flicker 3s linear infinite;
          animation: text-flicker 3s linear infinite;
}

.faulty-letter {
  opacity: 0.5;
  -webkit-animation: faulty-flicker 2s linear infinite;
          animation: faulty-flicker 2s linear infinite;
}

.glowing-btn1::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.7;
  -webkit-filter: blur(1em);
          filter: blur(1em);
  -webkit-transform: translateY(120%) rotateX(95deg) scale(1, 0.35);
          transform: translateY(120%) rotateX(95deg) scale(1, 0.35);
  background: #ff0000;
  background: var(--glow-color);
  pointer-events: none;
}

.glowing-btn1::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  background-color: #ff0000;
  background-color: var(--glow-color);
  box-shadow: 0 0 2em 0.2em #ff0000;
  box-shadow: 0 0 2em 0.2em var(--glow-color);
  transition: opacity 100ms linear;
}

.glowing-btn1:hover {
  /* color: rgba(0, 0, 0, 0.8); */
  color: rgba(243, 173, 184, 0.8);
  text-shadow: none;
  -webkit-animation: none;
          animation: none;
}

.glowing-btn1:hover .glowing-txt {
  -webkit-animation: none;
          animation: none;
}

.glowing-btn1:hover .faulty-letter {
  -webkit-animation: none;
          animation: none;
  text-shadow: none;
  opacity: 1;
}

.glowing-btn1:hover:before {
  -webkit-filter: blur(1.5em);
          filter: blur(1.5em);
  opacity: 1;
}

.glowing-btn1:hover:after {
  opacity: 1;
}

@-webkit-keyframes faulty-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 0.1;
  }
  4% {
    opacity: 0.5;
  }
  19% {
    opacity: 0.5;
  }
  21% {
    opacity: 0.1;
  }
  23% {
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  83% {
    opacity: 0.4;
  }

  87% {
    opacity: 1;
  }
}

@keyframes faulty-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 0.1;
  }
  4% {
    opacity: 0.5;
  }
  19% {
    opacity: 0.5;
  }
  21% {
    opacity: 0.1;
  }
  23% {
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  83% {
    opacity: 0.4;
  }

  87% {
    opacity: 1;
  }
}

@-webkit-keyframes text-flicker {
  0% {
    opacity: 0.1;
  }

  2% {
    opacity: 1;
  }

  8% {
    opacity: 0.1;
  }

  9% {
    opacity: 1;
  }

  12% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.3;
  }
  30% {
    opacity: 1;
  }

  70% {
    opacity: 0.7;
  }
  72% {
    opacity: 0.2;
  }

  77% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.9;
  }
}

@keyframes text-flicker {
  0% {
    opacity: 0.1;
  }

  2% {
    opacity: 1;
  }

  8% {
    opacity: 0.1;
  }

  9% {
    opacity: 1;
  }

  12% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.3;
  }
  30% {
    opacity: 1;
  }

  70% {
    opacity: 0.7;
  }
  72% {
    opacity: 0.2;
  }

  77% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.9;
  }
}

@-webkit-keyframes border-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 1;
  }
  4% {
    opacity: 0.1;
  }

  8% {
    opacity: 1;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@keyframes border-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 1;
  }
  4% {
    opacity: 0.1;
  }

  8% {
    opacity: 1;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .glowing-btn1{
    font-size: 1em;
  }
}

*{
--orange: '#fed693'
}
.heroneon {
    position: relative;
    display: inline-block;
    padding: 20px 25px;
    margin: 20px 0;
    color: #ff0000;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.5s;
    letter-spacing: 4px;
    overflow: hidden;
    font-size: 1.2rem;
    font-weight: 700;
  }

  @media screen and (max-width: 768px) {
    .heroneon {
      font-size: 1rem;
    }
  }
  .heroneon:hover {
    background: linear-gradient(-45deg, #ffb401, #ff0000);
    color: #fff;
    box-shadow: 0 0 5px #f39a05, 0 0 25px #f39a05, 0 0 50px #f39a05,
      0 0 200px #f39a05;
    -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
  }
  .heroneon:nth-child(1) {
    -webkit-filter: hue-rotate(0deg);
            filter: hue-rotate(0deg);
  }
  .heroneon:nth-child(2) {
    -webkit-filter: hue-rotate(110deg);
            filter: hue-rotate(110deg);
  }
  .heroneon span {
    position: absolute;
    display: block;
  }
  .heroneon span:nth-child(1) {
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #ff0000);
    -webkit-animation: animate1 1s linear infinite;
            animation: animate1 1s linear infinite;
  }
  @-webkit-keyframes animate1 {
    0% {
      left: -100%;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  @keyframes animate1 {
    0% {
      left: -100%;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  .heroneon span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #ff0000);
    -webkit-animation: animate2 1s linear infinite;
            animation: animate2 1s linear infinite;
    -webkit-animation-delay: 0.25s;
            animation-delay: 0.25s;
  }
  @-webkit-keyframes animate2 {
    0% {
      top: -100%;
    }
    50%,
    100% {
      top: 100%;
    }
  }
  @keyframes animate2 {
    0% {
      top: -100%;
    }
    50%,
    100% {
      top: 100%;
    }
  }
  .heroneon span:nth-child(3) {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #ff0000);
    -webkit-animation: animate3 1s linear infinite;
            animation: animate3 1s linear infinite;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  @-webkit-keyframes animate3 {
    0% {
      right: -100%;
    }
    50%,
    100% {
      right: 100%;
    }
  }
  @keyframes animate3 {
    0% {
      right: -100%;
    }
    50%,
    100% {
      right: 100%;
    }
  }
  .heroneon span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #ff0000);
    -webkit-animation: animate4 1s linear infinite;
            animation: animate4 1s linear infinite;
    -webkit-animation-delay: 0.75s;
            animation-delay: 0.75s;
  }
  @-webkit-keyframes animate4 {
    0% {
      bottom: -100%;
    }
    50%,
    100% {
      bottom: 100%;
    }
  }
  @keyframes animate4 {
    0% {
      bottom: -100%;
    }
    50%,
    100% {
      bottom: 100%;
    }
  }
.text-container {
  /* height: 100vh;
    width: 100vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 15rem; */

}

@media screen and (max-width: 768px) {
  .text-container{
    margin-top: 5rem;
  }
}

.text-container h1 {
  color: rgba(225, 225, 225, 0.01);
  background-image: url("https://images.pexels.com/photos/73873/star-clusters-rosette-nebula-star-galaxies-73873.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-animation: animate 5s ease-in-out infinite;
          animation: animate 5s ease-in-out infinite;
}

@-webkit-keyframes animate {
  0%,
  100% {
    background-position: left top;
  }
  25% {
    background-position: right bottom;
  }
  50% {
    background-position: left bottom;
  }
  75% {
    background-position: right top;
  }
}

@keyframes animate {
  0%,
  100% {
    background-position: left top;
  }
  25% {
    background-position: right bottom;
  }
  50% {
    background-position: left bottom;
  }
  75% {
    background-position: right top;
  }
}

*{
--orange: '#fed693'
}
.heroneon {
    position: relative;
    display: inline-block;
    padding: 20px 25px;
    margin: 20px 0;
    color: #ff0000;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.5s;
    letter-spacing: 4px;
    overflow: hidden;
    font-size: 1.2rem;
    font-weight: 700;
  }

  @media screen and (max-width: 768px) {
    .heroneon {
      font-size: 1rem;
    }
  }
  .heroneon:hover {
    background: linear-gradient(-45deg, #ffb401, #ff0000);
    color: #fff;
    box-shadow: 0 0 5px #f39a05, 0 0 25px #f39a05, 0 0 50px #f39a05,
      0 0 200px #f39a05;
    -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
  }
  .heroneon:nth-child(1) {
    -webkit-filter: hue-rotate(0deg);
            filter: hue-rotate(0deg);
  }
  .heroneon:nth-child(2) {
    -webkit-filter: hue-rotate(110deg);
            filter: hue-rotate(110deg);
  }
  .heroneon span {
    position: absolute;
    display: block;
  }
  .heroneon span:nth-child(1) {
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #ff0000);
    -webkit-animation: animate1 1s linear infinite;
            animation: animate1 1s linear infinite;
  }
  @-webkit-keyframes animate1 {
    0% {
      left: -100%;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  @keyframes animate1 {
    0% {
      left: -100%;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  .heroneon span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #ff0000);
    -webkit-animation: animate2 1s linear infinite;
            animation: animate2 1s linear infinite;
    -webkit-animation-delay: 0.25s;
            animation-delay: 0.25s;
  }
  @-webkit-keyframes animate2 {
    0% {
      top: -100%;
    }
    50%,
    100% {
      top: 100%;
    }
  }
  @keyframes animate2 {
    0% {
      top: -100%;
    }
    50%,
    100% {
      top: 100%;
    }
  }
  .heroneon span:nth-child(3) {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #ff0000);
    -webkit-animation: animate3 1s linear infinite;
            animation: animate3 1s linear infinite;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  @-webkit-keyframes animate3 {
    0% {
      right: -100%;
    }
    50%,
    100% {
      right: 100%;
    }
  }
  @keyframes animate3 {
    0% {
      right: -100%;
    }
    50%,
    100% {
      right: 100%;
    }
  }
  .heroneon span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #ff0000);
    -webkit-animation: animate4 1s linear infinite;
            animation: animate4 1s linear infinite;
    -webkit-animation-delay: 0.75s;
            animation-delay: 0.75s;
  }
  @-webkit-keyframes animate4 {
    0% {
      bottom: -100%;
    }
    50%,
    100% {
      bottom: 100%;
    }
  }
  @keyframes animate4 {
    0% {
      bottom: -100%;
    }
    50%,
    100% {
      bottom: 100%;
    }
  }
.text-container {
  /* height: 100vh;
    width: 100vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 15rem; */

}

@media screen and (max-width: 768px) {
  .text-container{
    margin-top: 5rem;
  }
}

.text-container h1 {
  color: rgba(225, 225, 225, 0.01);
  background-image: url("https://images.pexels.com/photos/3844796/pexels-photo-3844796.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-animation: animate 5s ease-in-out infinite;
          animation: animate 5s ease-in-out infinite;
}

@-webkit-keyframes animate {
  0%,
  100% {
    background-position: left top;
  }
  25% {
    background-position: right bottom;
  }
  50% {
    background-position: left bottom;
  }
  75% {
    background-position: right top;
  }
}

@keyframes animate {
  0%,
  100% {
    background-position: left top;
  }
  25% {
    background-position: right bottom;
  }
  50% {
    background-position: left bottom;
  }
  75% {
    background-position: right top;
  }
}

.buttonPopup1 {
  position: fixed;
  bottom: 8rem;
  right: 7rem;
  width: 100px;
  border: 1px solid yellow;

  display: flex;
  z-index: 1000;
  padding: 0.5rem;
  flex-direction: column;
  border-radius: 9999px;
  color: #ffffff;
  background-color: transparent;
  transition-duration: 700ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  align-items: center;
  border: none;
}

@media (max-width: 640px) {
  .buttonPopup1 {
    right: -0.5rem;
    bottom: 6rem;
  }
}

.buttonPopup {
  position: fixed;
  bottom: 4rem;
  right: 6rem;

  display: flex;
  z-index: 1000;
  padding: 0.5rem;
  flex-direction: column;
  border-radius: 9999px;
  color: #ffffff;
  background-color: transparent;
  transition-duration: 700ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  align-items: center;
  border: none;
}

@media (max-width: 640px) {
  .buttonPopup {
    right: -1.2rem;
    bottom: 2rem;
  }
}

.buttonPopup:hover {
  /* background-color: #bfdbfe;
  scale: 1.1; */
}

.backtoTop {
  display: none;
  position: absolute;
  bottom: 1.5rem;
  left: 3.5rem;
  z-index: 10;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  flex-direction: column;
  border-radius: 9999px;
  color: #ffffff;
  cursor: pointer;
}

@media (min-width: 640px) {
  .backtoTop {
    display: block;
    left: 1rem;
    bottom: 5rem;
  }
}

.buttonSvg {
  width: 2rem;
  height: 2rem;
  /* border: 1px solid #fff; */
  margin-bottom: 5px;
}

.modalContainer {
  display: flex;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1001;
  flex-direction: column;
  border-radius: 1rem;
  background-color: #f1f1f1;
}

.modalContainerInside {
  display: flex;
  padding-top: 1rem;
  padding-left: 1rem;
  flex-direction: column;
}

.modalh5 {
  padding-top: 1rem;
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 500;
  letter-spacing: -0.025em;
  color: #ff0000;
  cursor: pointer;
  border-bottom: 1px solid #ff0000;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.modalp {
  padding-top: 1rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 500;
  letter-spacing: -0.025em;
  color: #ff0000;
}

.modalspan {
  padding-top: 1rem;
  font-weight: 500;
  letter-spacing: -0.025em;
  color: #000;
}

.modalform {
  padding-top: 0rem;
  min-height: 300px;
}

.forminside {
  border-style: none;
  outline-style: none;
  color: #000000;
}

.modalX {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #ffffff;
  cursor: pointer;
}

.modalSvg {
  width: 1.5rem;
  height: 1.5rem;
}

/* nhap nhay button */
.modalForm {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
}

.formGroup {
  display: flex;
  flex-direction: column;
}

.formLabel {
  font-weight: 500;
  margin-bottom: 6px;
}

.formInput,
.formTextarea,
.formSelect {
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px 12px;
  font-size: 0.95rem;
  transition: border-color 0.3s;
  width: 95%;
}

.formInput:focus,
.formTextarea:focus,
.formSelect:focus {
  border-color: #ff0000;
  outline: none;
}

.submit-button {
  border-radius: 4px;
  margin-top: 10px;
  background-color: #ff0000; /* Red background */
  white-space: nowrap;
  padding: 10px 20px;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-out; /* Smooth transition for hover state */
  width: 95%;
}

.submit-button:hover {
  color: black; /* Text color changes to black on hover */
  scale: 1.1;
}

.form-select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  font-size: 1rem;
  outline: none;
  cursor: pointer;
}

.form-select:hover {
  background-color: #ff0000; /* Red background when hovering over select */
  color: white; /* White text on hover */
}

/* Focus state for the select input */
.form-select:focus {
  border-color: #ff0000; /* Red border when focused */
}

/* Style for individual option items when hovering over them */
.form-select option:hover {
  background-color: #ff0000; /* Red background on hover over option */
  color: white; /* White text on hover over option */
}

/* Focused option when navigating with arrow keys */
.form-select option:focus {
  background-color: #ff0000; /* Red background when focused via keyboard */
  color: white; /* White text when focused via keyboard */
}

/* Selected option */
.form-select option:checked {
  background-color: #ff0000; /* Red background for selected option */
  color: white; /* White text for selected option */
}

.animated-button {
  background: linear-gradient(-30deg, #0b1b3d 50%, #08142b 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  color: #d4e0f7;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button::before {
  content: "";
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #8592ad;
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
}

.animated-button:hover::before {
  opacity: 0.2;
}

.animated-button span {
  position: absolute;
}

.animated-button span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to left, rgba(8, 20, 43, 0), #2662d9);
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
}

@-webkit-keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.animated-button span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to top, rgba(8, 20, 43, 0), #2662d9);
  -webkit-animation: 2s animateRight linear -1s infinite;
  animation: 2s animateRight linear -1s infinite;
}

@-webkit-keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.animated-button span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(8, 20, 43, 0), #2662d9);
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@-webkit-keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.animated-button span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to bottom, rgba(8, 20, 43, 0), #2662d9);
  -webkit-animation: 2s animateLeft linear -1s infinite;
  animation: 2s animateLeft linear -1s infinite;
}

@-webkit-keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

.animated-button1 {
  background: linear-gradient(-30deg, #3d0b0b 50%, #2b0808 50%);
  /* background: #f9f9f8; */

  padding: 15px 20px;
  margin: 12px;
  /* border-radius: 9999px; */
  height: 20px;
  width: 40px;

  display: inline-block;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  color: #fff;
  font-size: 18px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);

  /* flash background */
  -webkit-animation: glowing 1300ms infinite;
  animation: glowing 1300ms infinite;
}

@media (max-width: 640px) {
  .animated-button1 {
    padding: 15px 25px;
    font-size: 16px;
  }
}

@-webkit-keyframes glowing {
  0% {
    background-color: #ff0000;
    -webkit-box-shadow: 0 0 3px #ff0000;
  }
  50% {
    background-color: #ff0000;
    -webkit-box-shadow: 0 0 15px #ff0000;
  }
  100% {
    background-color: #ff0000;
    -webkit-box-shadow: 0 0 3px #ff0000;
  }
}
@keyframes glowing {
  0% {
    background-color: #ff0000;
    box-shadow: 0 0 3px #ff0000;
  }
  50% {
    background-color: #ff0000;
    box-shadow: 0 0 15px #ff0000;
  }
  100% {
    background-color: #ff0000;
    box-shadow: 0 0 3px #ff0000;
  }
}
.animated-button1::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ad8585;
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
}

.animated-button1:hover::before {
  opacity: 0.2;
}

.animated-button1 span {
  position: absolute;
}

.animated-button1 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to left, rgba(43, 8, 8, 0), #d92626);
  -webkit-animation: 1s animateTop linear infinite;
  animation: 1s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.animated-button1 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to top, rgba(43, 8, 8, 0), #d92626);
  -webkit-animation: 1s animateRight linear -1s infinite;
  animation: 1s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.animated-button1 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(43, 8, 8, 0), #d92626);
  -webkit-animation: 1s animateBottom linear infinite;
  animation: 1s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.animated-button1 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to bottom, rgba(43, 8, 8, 0), #d92626);
  -webkit-animation: 1s animateLeft linear -1s infinite;
  animation: 1s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

.animated-button2 {
  background: linear-gradient(-30deg, #3d240b 50%, #2b1a08 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  color: #f7e6d4;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button2::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ad9985;
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
}

.animated-button2:hover::before {
  opacity: 0.2;
}

.animated-button2 span {
  position: absolute;
}

.animated-button2 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to left, rgba(43, 26, 8, 0), #d98026);
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.animated-button2 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to top, rgba(43, 26, 8, 0), #d98026);
  -webkit-animation: 2s animateRight linear -1s infinite;
  animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.animated-button2 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(43, 26, 8, 0), #d98026);
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.animated-button2 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to bottom, rgba(43, 26, 8, 0), #d98026);
  -webkit-animation: 2s animateLeft linear -1s infinite;
  animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

.animated-button3 {
  background: linear-gradient(-30deg, #3d3d0b 50%, #2b2b08 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  color: #f7f7d4;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button3::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #adad85;
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
}

.animated-button3:hover::before {
  opacity: 0.2;
}

.animated-button3 span {
  position: absolute;
}

.animated-button3 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to left, rgba(43, 43, 8, 0), #d9d926);
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.animated-button3 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to top, rgba(43, 43, 8, 0), #d9d926);
  -webkit-animation: 2s animateRight linear -1s infinite;
  animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.animated-button3 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(43, 43, 8, 0), #d9d926);
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.animated-button3 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to bottom, rgba(43, 43, 8, 0), #d9d926);
  -webkit-animation: 2s animateLeft linear -1s infinite;
  animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

.animated-button4 {
  background: linear-gradient(-30deg, #243d0b 50%, #1a2b08 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  color: #e6f7d4;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button4::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #99ad85;
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
}

.animated-button4:hover::before {
  opacity: 0.2;
}

.animated-button4 span {
  position: absolute;
}

.animated-button4 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to left, rgba(26, 43, 8, 0), #80d926);
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.animated-button4 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to top, rgba(26, 43, 8, 0), #80d926);
  -webkit-animation: 2s animateRight linear -1s infinite;
  animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.animated-button4 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(26, 43, 8, 0), #80d926);
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.animated-button4 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to bottom, rgba(26, 43, 8, 0), #80d926);
  -webkit-animation: 2s animateLeft linear -1s infinite;
  animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

.animated-button5 {
  background: linear-gradient(-30deg, #0b3d0b 50%, #082b08 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  color: #d4f7d4;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button5::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #85ad85;
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
}

.animated-button5:hover::before {
  opacity: 0.2;
}

.animated-button5 span {
  position: absolute;
}

.animated-button5 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to left, rgba(8, 43, 8, 0), #26d926);
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.animated-button5 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to top, rgba(8, 43, 8, 0), #26d926);
  -webkit-animation: 2s animateRight linear -1s infinite;
  animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.animated-button5 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(8, 43, 8, 0), #26d926);
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.animated-button5 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to bottom, rgba(8, 43, 8, 0), #26d926);
  -webkit-animation: 2s animateLeft linear -1s infinite;
  animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

.animated-button6 {
  background: linear-gradient(-30deg, #0b3d24 50%, #082b1a 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  color: #d4f7e6;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button6::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #85ad99;
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
}

.animated-button6:hover::before {
  opacity: 0.2;
}

.animated-button6 span {
  position: absolute;
}

.animated-button6 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to left, rgba(8, 43, 26, 0), #26d980);
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.animated-button6 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to top, rgba(8, 43, 26, 0), #26d980);
  -webkit-animation: 2s animateRight linear -1s infinite;
  animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.animated-button6 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(8, 43, 26, 0), #26d980);
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.animated-button6 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to bottom, rgba(8, 43, 26, 0), #26d980);
  -webkit-animation: 2s animateLeft linear -1s infinite;
  animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

.animated-button7 {
  background: linear-gradient(-30deg, #0b3d3d 50%, #082b2b 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  color: #d4f7f7;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button7::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #85adad;
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
}

.animated-button7:hover::before {
  opacity: 0.2;
}

.animated-button7 span {
  position: absolute;
}

.animated-button7 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to left, rgba(8, 43, 43, 0), #26d9d9);
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.animated-button7 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to top, rgba(8, 43, 43, 0), #26d9d9);
  -webkit-animation: 2s animateRight linear -1s infinite;
  animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.animated-button7 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(8, 43, 43, 0), #26d9d9);
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.animated-button7 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to bottom, rgba(8, 43, 43, 0), #26d9d9);
  -webkit-animation: 2s animateLeft linear -1s infinite;
  animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

.animated-button8 {
  background: linear-gradient(-30deg, #0b243d 50%, #081a2b 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  color: #d4e6f7;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button8::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #8599ad;
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
}

.animated-button8:hover::before {
  opacity: 0.2;
}

.animated-button8 span {
  position: absolute;
}

.animated-button8 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to left, rgba(8, 26, 43, 0), #2680d9);
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.animated-button8 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to top, rgba(8, 26, 43, 0), #2680d9);
  -webkit-animation: 2s animateRight linear -1s infinite;
  animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.animated-button8 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(8, 26, 43, 0), #2680d9);
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.animated-button8 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to bottom, rgba(8, 26, 43, 0), #2680d9);
  -webkit-animation: 2s animateLeft linear -1s infinite;
  animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

.animated-button9 {
  background: linear-gradient(-30deg, #0b0b3d 50%, #08082b 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  color: #d4d4f7;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button9::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #8585ad;
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
}

.animated-button9:hover::before {
  opacity: 0.2;
}

.animated-button9 span {
  position: absolute;
}

.animated-button9 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to left, rgba(8, 8, 43, 0), #2626d9);
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.animated-button9 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to top, rgba(8, 8, 43, 0), #2626d9);
  -webkit-animation: 2s animateRight linear -1s infinite;
  animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.animated-button9 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(8, 8, 43, 0), #2626d9);
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.animated-button9 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to bottom, rgba(8, 8, 43, 0), #2626d9);
  -webkit-animation: 2s animateLeft linear -1s infinite;
  animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

.animated-button10 {
  background: linear-gradient(-30deg, #240b3d 50%, #1a082b 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  color: #e6d4f7;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button10::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #9985ad;
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
}

.animated-button10:hover::before {
  opacity: 0.2;
}

.animated-button10 span {
  position: absolute;
}

.animated-button10 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to left, rgba(26, 8, 43, 0), #8026d9);
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.animated-button10 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to top, rgba(26, 8, 43, 0), #8026d9);
  -webkit-animation: 2s animateRight linear -1s infinite;
  animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.animated-button10 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(26, 8, 43, 0), #8026d9);
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.animated-button10 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to bottom, rgba(26, 8, 43, 0), #8026d9);
  -webkit-animation: 2s animateLeft linear -1s infinite;
  animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

.animated-button11 {
  background: linear-gradient(-30deg, #3d0b3d 50%, #2b082b 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  color: #f7d4f7;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button11::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ad85ad;
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
}

.animated-button11:hover::before {
  opacity: 0.2;
}

.animated-button11 span {
  position: absolute;
}

.animated-button11 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to left, rgba(43, 8, 43, 0), #d926d9);
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.animated-button11 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to top, rgba(43, 8, 43, 0), #d926d9);
  -webkit-animation: 2s animateRight linear -1s infinite;
  animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.animated-button11 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(43, 8, 43, 0), #d926d9);
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.animated-button11 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to bottom, rgba(43, 8, 43, 0), #d926d9);
  -webkit-animation: 2s animateLeft linear -1s infinite;
  animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

.animated-button12 {
  background: linear-gradient(-30deg, #3d0b24 50%, #2b081a 50%);
  padding: 20px 40px;
  margin: 12px;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  color: #f7d4e6;
  font-size: 20px;
  letter-spacing: 2.5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

.animated-button12::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ad8599;
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
}

.animated-button12:hover::before {
  opacity: 0.2;
}

.animated-button12 span {
  position: absolute;
}

.animated-button12 span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to left, rgba(43, 8, 26, 0), #d92680);
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.animated-button12 span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to top, rgba(43, 8, 26, 0), #d92680);
  -webkit-animation: 2s animateRight linear -1s infinite;
  animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.animated-button12 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(43, 8, 26, 0), #d92680);
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.animated-button12 span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 2px;
  background: linear-gradient(to bottom, rgba(43, 8, 26, 0), #d92680);
  -webkit-animation: 2s animateLeft linear -1s infinite;
  animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}
/*# sourceMappingURL=button.css.map */

:root {
  --glow-color: #ff0000;
}

@media (max-width: 640px) {
  :root {
    --glow-color: #fff;
  }
}

.glowing-btn {
  position: relative;
  color: #ff0000;
  color: var(--glow-color);
  cursor: pointer;
  padding: 0.2em 0.4em;
  border: 0.1em solid #ff0000;
  border: 0.1em solid var(--glow-color);
  border-radius: 0.45em;
  background: #f1f1f1;
  -webkit-perspective: 2em;
          perspective: 2em;
  font-family: "Raleway", sans-serif;
  font-size: 1.5em;
  font-weight: 900;
  letter-spacing: 1em;
  box-shadow: inset 0px 0px 0.5em 0px #ff0000,
    0px 0px 0.5em 0px #ff0000;
  box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  -webkit-animation: border-flicker 2s linear infinite;
          animation: border-flicker 2s linear infinite;
}

.glowing-txt {
  float: left;
  margin-right: -0.8em;
  -webkit-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
    0 0 0.45em #ff0000;
  -webkit-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
    0 0 0.45em var(--glow-color);
  -moz-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
    0 0 0.45em #ff0000;
  -moz-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
    0 0 0.45em var(--glow-color);
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em #ff0000;
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em var(--glow-color);
  -webkit-animation: text-flicker 3s linear infinite;
          animation: text-flicker 3s linear infinite;
}

.faulty-letter {
  opacity: 0.5;
  -webkit-animation: faulty-flicker 2s linear infinite;
          animation: faulty-flicker 2s linear infinite;
}

.glowing-btn::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.7;
  -webkit-filter: blur(1em);
          filter: blur(1em);
  -webkit-transform: translateY(120%) rotateX(95deg) scale(1, 0.35);
          transform: translateY(120%) rotateX(95deg) scale(1, 0.35);
  background: #ff0000;
  background: var(--glow-color);
  pointer-events: none;
}

.glowing-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  background-color: #ff0000;
  background-color: var(--glow-color);
  box-shadow: 0 0 2em 0.2em #ff0000;
  box-shadow: 0 0 2em 0.2em var(--glow-color);
  transition: opacity 100ms linear;
}

.glowing-btn:hover {
  color: rgba(255, 255, 255, 0.8);
  text-shadow: none;
  -webkit-animation: none;
          animation: none;
}

.glowing-btn:hover .glowing-txt {
  -webkit-animation: none;
          animation: none;
}

.glowing-btn:hover .faulty-letter {
  -webkit-animation: none;
          animation: none;
  text-shadow: none;
  opacity: 1;
}

.glowing-btn:hover:before {
  -webkit-filter: blur(1.5em);
          filter: blur(1.5em);
  opacity: 1;
}

.glowing-btn:hover:after {
  opacity: 1;
}

@-webkit-keyframes faulty-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 0.1;
  }
  4% {
    opacity: 0.5;
  }
  19% {
    opacity: 0.5;
  }
  21% {
    opacity: 0.1;
  }
  23% {
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  83% {
    opacity: 0.4;
  }

  87% {
    opacity: 1;
  }
}

@keyframes faulty-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 0.1;
  }
  4% {
    opacity: 0.5;
  }
  19% {
    opacity: 0.5;
  }
  21% {
    opacity: 0.1;
  }
  23% {
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  83% {
    opacity: 0.4;
  }

  87% {
    opacity: 1;
  }
}

@-webkit-keyframes text-flicker {
  0% {
    opacity: 0.1;
  }

  2% {
    opacity: 1;
  }

  8% {
    opacity: 0.1;
  }

  9% {
    opacity: 1;
  }

  12% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.3;
  }
  30% {
    opacity: 1;
  }

  70% {
    opacity: 0.7;
  }
  72% {
    opacity: 0.2;
  }

  77% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.9;
  }
}

@keyframes text-flicker {
  0% {
    opacity: 0.1;
  }

  2% {
    opacity: 1;
  }

  8% {
    opacity: 0.1;
  }

  9% {
    opacity: 1;
  }

  12% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.3;
  }
  30% {
    opacity: 1;
  }

  70% {
    opacity: 0.7;
  }
  72% {
    opacity: 0.2;
  }

  77% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.9;
  }
}

@-webkit-keyframes border-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 1;
  }
  4% {
    opacity: 0.1;
  }

  8% {
    opacity: 1;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@keyframes border-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 1;
  }
  4% {
    opacity: 0.1;
  }

  8% {
    opacity: 1;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .glowing-btn {
    font-size: 1em;
  }
}

.tooltip-container {
    --background: transparent;
    position: relative;
    background: var(--background);
    cursor: pointer;
    transition: background 0.3s;
    font-size: 17px;
    padding: 0.7em 1.8em;
  }
  
  .tooltip {
    position: absolute;
    top: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%); /* Adjusted the initial position */
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    background: #fff;
    color: #ff0000;
    border-radius: 0.3em;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    text-align: center;
    font-size: 14px;
    width: 120%; /* Adjusted the width */
    padding: 0.5em 1em; /* Adjusted padding */
    white-space: nowrap; /* Prevent text wrapping */
  }
  
  .tooltip-container:hover .tooltip {
    top: -50%; /* Adjusted the tooltip position */
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateX(-50%) translateY(0);
            transform: translateX(-50%) translateY(0);
  }



  
  @media (max-width: 640px), (pointer: coarse), (hover: none)  {
    .tooltip-container:focus::after .tooltip {
        top: -50%; /* Adjusted the tooltip position */
        opacity: 1;
        pointer-events: auto;
        -webkit-transform: translateX(-50%) translateY(0);
                transform: translateX(-50%) translateY(0);
      }
  }
  
.divnotfound{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.5);
}
.tooltip-container1 {
    --background: #fff;
    position: relative;
    background: var(--background);
    cursor: pointer;
    transition: background 0.3s;
    font-size: 17px;
    padding: 0.7em 1.8em;
  }
  
  .tooltip1 {
    position: absolute;
    top: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%); /* Adjusted the initial position */
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    background: #fff;
    color: #ff0000;
    border-radius: 0.3em;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    text-align: center;
    font-size: 14px;
    width: 120%; /* Adjusted the width */
    padding: 0.5em 1em; /* Adjusted padding */
    white-space: nowrap; /* Prevent text wrapping */
  }
  
  .tooltip-container1:hover .tooltip1 {
    top: -50%; /* Adjusted the tooltip position */
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateX(-50%) translateY(0);
            transform: translateX(-50%) translateY(0);
  }



  
  @media (max-width: 640px), (pointer: coarse), (hover: none)  {
    .tooltip-container1:focus::after .tooltip1 {
        top: -50%; /* Adjusted the tooltip position */
        opacity: 1;
        pointer-events: auto;
        -webkit-transform: translateX(-50%) translateY(0);
                transform: translateX(-50%) translateY(0);
      }
  }
  
