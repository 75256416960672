.buttonPopup1 {
  position: fixed;
  bottom: 8rem;
  right: 7rem;
  width: 100px;
  border: 1px solid yellow;

  display: flex;
  z-index: 1000;
  padding: 0.5rem;
  flex-direction: column;
  border-radius: 9999px;
  color: #ffffff;
  background-color: transparent;
  transition-duration: 700ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  align-items: center;
  border: none;
}

@media (max-width: 640px) {
  .buttonPopup1 {
    right: -0.5rem;
    bottom: 6rem;
  }
}

.buttonPopup {
  position: fixed;
  bottom: 4rem;
  right: 6rem;

  display: flex;
  z-index: 1000;
  padding: 0.5rem;
  flex-direction: column;
  border-radius: 9999px;
  color: #ffffff;
  background-color: transparent;
  transition-duration: 700ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  align-items: center;
  border: none;
}

@media (max-width: 640px) {
  .buttonPopup {
    right: -1.2rem;
    bottom: 2rem;
  }
}

.buttonPopup:hover {
  /* background-color: #bfdbfe;
  scale: 1.1; */
}

.backtoTop {
  display: none;
  position: absolute;
  bottom: 1.5rem;
  left: 3.5rem;
  z-index: 10;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  flex-direction: column;
  border-radius: 9999px;
  color: #ffffff;
  cursor: pointer;
}

@media (min-width: 640px) {
  .backtoTop {
    display: block;
    left: 1rem;
    bottom: 5rem;
  }
}

.buttonSvg {
  width: 2rem;
  height: 2rem;
  /* border: 1px solid #fff; */
  margin-bottom: 5px;
}

.modalContainer {
  display: flex;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1001;
  flex-direction: column;
  border-radius: 1rem;
  background-color: #f1f1f1;
}

.modalContainerInside {
  display: flex;
  padding-top: 1rem;
  padding-left: 1rem;
  flex-direction: column;
}

.modalh5 {
  padding-top: 1rem;
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 500;
  letter-spacing: -0.025em;
  color: #ff0000;
  cursor: pointer;
  border-bottom: 1px solid #ff0000;
  width: fit-content;
}

.modalp {
  padding-top: 1rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 500;
  letter-spacing: -0.025em;
  color: #ff0000;
}

.modalspan {
  padding-top: 1rem;
  font-weight: 500;
  letter-spacing: -0.025em;
  color: #000;
}

.modalform {
  padding-top: 0rem;
  min-height: 300px;
}

.forminside {
  border-style: none;
  outline-style: none;
  color: #000000;
}

.modalX {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #ffffff;
  cursor: pointer;
}

.modalSvg {
  width: 1.5rem;
  height: 1.5rem;
}

/* nhap nhay button */
.modalForm {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.formGroup {
  display: flex;
  flex-direction: column;
}

.formLabel {
  font-weight: 500;
  margin-bottom: 6px;
}

.formInput,
.formTextarea,
.formSelect {
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px 12px;
  font-size: 0.95rem;
  transition: border-color 0.3s;
  width: 95%;
}

.formInput:focus,
.formTextarea:focus,
.formSelect:focus {
  border-color: #ff0000;
  outline: none;
}

.submit-button {
  border-radius: 4px;
  margin-top: 10px;
  background-color: #ff0000; /* Red background */
  white-space: nowrap;
  padding: 10px 20px;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-out; /* Smooth transition for hover state */
  width: 95%;
}

.submit-button:hover {
  color: black; /* Text color changes to black on hover */
  scale: 1.1;
}

.form-select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  font-size: 1rem;
  outline: none;
  cursor: pointer;
}

.form-select:hover {
  background-color: #ff0000; /* Red background when hovering over select */
  color: white; /* White text on hover */
}

/* Focus state for the select input */
.form-select:focus {
  border-color: #ff0000; /* Red border when focused */
}

/* Style for individual option items when hovering over them */
.form-select option:hover {
  background-color: #ff0000; /* Red background on hover over option */
  color: white; /* White text on hover over option */
}

/* Focused option when navigating with arrow keys */
.form-select option:focus {
  background-color: #ff0000; /* Red background when focused via keyboard */
  color: white; /* White text when focused via keyboard */
}

/* Selected option */
.form-select option:checked {
  background-color: #ff0000; /* Red background for selected option */
  color: white; /* White text for selected option */
}
