@import url("https://fonts.googleapis.com/css?family=Montserrat:600");

#processing-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  background-color: rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 9999;
}

#wrapper {
  position: relative;
  max-width: 400px;
  min-width: 300px;
  margin: 1rem;
  animation: out 0.3s forwards ease;
  animation-delay: 5s;
  transform: scale(1);
  opacity: 1;
  transform-origin: center -80%;
  margin: 0 auto;
  height: auto; /* Flexible height */

  backdrop-filter: blur(10px);

  -webkit-backdrop-filter: blur(10px);

  /* background-color: rgba(255, 255, 255, 0.1); */
}

@keyframes out {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

#wrapper .loader {
  animation: rotation 3.5s forwards linear;
  position: absolute;
  top: -120px;
  left: calc(50% - 35px);
  border: 5px solid #000;
  border-radius: 50%;
  border-top-color: #ff0000;
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }
  84% {
    transform: rotate(500deg);
  }
  95% {
    border: 5px solid #000;
    border-top-color: #ff0000;
  }
  100% {
    transform: rotate(1800deg);
    border: 5px solid #ff0000;
  }
}

#wrapper .loader::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 2em;
  color: #ff0000;
  animation: overspin 0.5s forwards cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-delay: 3.4s;
  transform: rotate(180deg);
  opacity: 0;
}

@keyframes overspin {
  0% {
    transform: rotate(180deg);
    opacity: 0;
  }
  100% {
    transform: rotate(0);
    opacity: 1;
  }
}

#wrapper #mouse {
  position: absolute;
  top: 100px;
  left: 80%;
  animation: mouse-pos 3.5s forwards, mouse-pos2 1s forwards;
  animation-delay: 0s, 4s;
  z-index: 20;
}

@keyframes mouse-pos {
  30% {
    top: 100px;
    left: 80%;
  }
  50% {
    top: 15px;
    left: 4%;
  }
  60% {
    top: 15px;
    left: 4%;
  }
  75% {
    top: 5px;
    left: 7.3%;
  }
  85% {
    top: 5px;
    left: 7.3%;
  }
  95% {
    top: 15px;
    left: 105%;
  }
  100% {
    top: 15px;
    left: 105%;
  }
}

@keyframes mouse-pos2 {
  0% {
    top: 15px;
    left: 105%;
  }
  100% {
    top: -85px;
    left: 53%;
  }
}

#wrapper #mouse::after {
  content: "\f245";
  position: absolute;
  top: 0;
  left: 0;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 20px;
  animation: mouse-cont 2s forwards;
  animation-delay: 1.7s;
  color: #ff0000;
  text-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);
}

@keyframes mouse-cont {
  0% {
    font-size: 20px;
  }
  2% {
    font-size: 15px;
  }
  4% {
    font-size: 20px;
  }
  33% {
    content: "\f245";
  }
  34% {
    content: "\f337";
  }
  80% {
    content: "\f337";
  }
  81% {
    content: "\f245";
  }
}

#wrapper #mouse::before {
  content: "";
  position: absolute;
  top: -22px;
  left: -24px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #222f3e;
  animation: circle 3.5s forwards;
  animation-delay: 1.7s;
  opacity: 0;
  transform: scale(0);
}

@keyframes circle {
  0% {
    transform: scale(0);
  }
  4% {
    opacity: 1;
  }
  8% {
    transform: scale(1);
    opacity: 0;
  }
  92% {
    transform: scale(0);
    opacity: 0;
  }
  95% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

#wrapper .loading-bar {
  width: 100%;
  height: 30px;
  background: #dfe6e9;
  border-radius: 5px;
}

#wrapper .progress-bar {
  animation: progress 3.5s forwards;
  width: 6%;
  height: 100%;
  background: #ff0000;
  border-radius: 5px;
  border: 0 solid #c1c1c1;
}

@keyframes progress {
  0% {
    width: 6%;
  }
  50% {
    border: 0 solid #c1c1c1;
  }
  51% {
    border: 4px solid #c1c1c1;
  }
  85% {
    width: 11%;
  }
  95% {
    border: 4px solid #c1c1c1;
    width: 100%;
  }
  100% {
    width: 100%;
  }
}

#wrapper .status {
  margin-top: 10px;
}

#wrapper .state {
  float: left;
  font-size: 0.9em;
  letter-spacing: 1pt;
  text-transform: uppercase;
  width: 100px;
  height: 20px;
  position: relative;
}

#wrapper .state::before {
  content: "Uploading...";
  position: absolute;
  left: 00%;
  top: 0;
  animation: fadeLeft 0.5s forwards ease;
  animation-delay: 3.2s;
}

@keyframes fadeLeft {
  0% {
    text-indent: 0;
    opacity: 1;
  }
  100% {
    text-indent: -100px;
    opacity: 0;
  }
}

#wrapper .state::after {
  content: "Complete";
  position: absolute;
  left: 0;
  top: 0;
  text-indent: 100px;
  opacity: 0;
  animation: fadeLeft2 0.5s forwards ease;
  animation-delay: 3.2s;
}

@keyframes fadeLeft2 {
  0% {
    text-indent: 100px;
    opacity: 0;
  }
  100% {
    text-indent: 0;
    opacity: 1;
  }
}

#wrapper .percentage {
  float: right;
}

#wrapper .percentage::before {
  content: "100%";
  font-size: 0.9em;
  letter-spacing: 1pt;
  animation: percentage-slow 3s forwards, percentage-fast 0.4s forwards;
  animation-delay: 0s, 3s;
}

@keyframes percentage-slow {
  0% {
    content: "6%";
  }
  25% {
    content: "7%";
  }
  50% {
    content: "8%";
  }
  75% {
    content: "9%";
  }
  100% {
    content: "10%";
  }
}

@keyframes percentage-fast {
  20% {
    content: "20%";
  }
  30% {
    content: "30%";
  }
  40% {
    content: "40%";
  }
  50% {
    content: "50%";
  }
  60% {
    content: "60%";
  }
  70% {
    content: "70%";
  }
  80% {
    content: "80%";
  }
  90% {
    content: "90%";
  }
  100% {
    content: "100%";
  }
}
